@import "https://fonts.googleapis.com/css?family=Vollkorn:400,400italic,700,700italic&subset=latin";
img {
  max-width: 100%;
  filter: drop-shadow(0 6mm 4mm #838383);
}

img[alt="landing"] {
  border-radius: 10px;
  margin-bottom: 50px;
}

img[alt="cka"] {
  width: 200px;
}

table {
  width: 100%;
}

table, th, td {
  padding: 5px;
}

html, body {
  max-width: 42em;
  background: #fefefe;
  margin: auto;
  padding: 1em;
}

body {
  color: #333;
  text-align: justify;
  font: 1.3em / 1.4 Vollkorn, Palatino, Times;
}

header, nav, article, footer {
  width: 700px;
  margin: 0 auto;
}

article {
  min-height: 400px;
  margin-top: 4em;
  margin-bottom: 4em;
}

footer {
  margin-bottom: 50px;
}

video {
  border: 1px solid #ddd;
  margin: 2em 0;
}

nav {
  border-bottom: 1px solid #ddd;
  padding: 1em 0;
  font-size: .9em;
  font-style: italic;
}

nav p {
  margin: 0;
}

h1 {
  margin-top: 0;
  font-weight: normal;
}

h2 {
  font-weight: normal;
}

h3 {
  margin-top: 3em;
  font-style: italic;
  font-weight: normal;
}

p {
  -webkit-hypens: auto;
  -moz-hypens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  margin-top: 0;
}

ul {
  padding-left: 1.2em;
  list-style: square;
}

ol {
  padding-left: 1.2em;
}

blockquote {
  border-left: 1px solid #ddd;
  margin-left: 1em;
  padding-left: 1em;
}

code {
  background: #fff;
  font-family: Consolas, Menlo, Monaco, monospace, serif;
  font-size: .9em;
}

a {
  color: #2484c1;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a img {
  border: none;
}

h1 a, h1 a:hover {
  color: #333;
  text-decoration: none;
}

hr {
  color: #ddd;
  height: 1px;
  border: 0;
  border-top: 1px solid #ddd;
  border-bottom-width: medium;
  margin: 2em 0;
}

p#heart {
  text-align: center;
  color: #ccc;
  font-size: 2em;
  line-height: 1;
}

.red {
  color: #b50000;
}

body#index li {
  margin-bottom: 1em;
}

@media only screen and (max-device-width: 1024px) {
  body {
    font-size: 120%;
    line-height: 1.4;
  }
}

@media only screen and (max-device-width: 480px) {
  body {
    text-align: left;
  }

  article, footer {
    width: auto;
  }

  article {
    padding: 0 10px;
  }
}

/*# sourceMappingURL=index.1780f04d.css.map */
